import moment from "moment";

function padded(x: string) {
  if (x.length === 1) {
    return `0${x}`;
  }
  return x;
}

function getTimeUntilMidnight(): string {
  const midnight = moment().endOf("day");
  const dur = moment.duration(midnight.diff(moment()));
  const hours = dur.hours().toString();
  const mins = padded(dur.minutes().toString());
  const secs = padded(dur.seconds().toString());

  return `${hours}:${mins}:${secs}`;
}

export default getTimeUntilMidnight;
