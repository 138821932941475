import * as React from "react";
import { Box, Divider, ListItemButton, Typography } from "@mui/material";
import colours from "../style/colours";
import getTimeUntilMidnight from "../countdown/getTimeUntilMidnight";
import { useState } from "react";

function LiveInListItem() {
  const [timeUntilMidnight, setTimeUntilMidnight] = useState(
    getTimeUntilMidnight()
  );

  setInterval(() => {
    setTimeUntilMidnight(getTimeUntilMidnight());
  }, 1000);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          height: 25,
        }}
      >
        <ListItemButton
          sx={{
            bgcolor: "background.paper",
            "&:focus": {
              backgroundColor: "white",
            },
            paddingLeft: 0,
            paddingRight: 0,
          }}
          selected={false}
          focusRipple={false}
          disableRipple={true}
          disableTouchRipple={true}
        >
          <Typography
            variant="body2"
            fontFamily={"Fira Code Variable"}
            style={{ textTransform: "uppercase" }}
            sx={{
              // display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              fontWeight: "500",
              // maxHeight: "80px",
              flexGrow: 100,
              color: colours.primary,
            }}
          >
            <div>{"LIVE IN: " + timeUntilMidnight}</div>
          </Typography>
        </ListItemButton>
      </Box>
      <Divider />
    </Box>
  );
}

export default LiveInListItem;
