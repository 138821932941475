import { USER_API_ROUTE } from "../apiRoutes";
import IUserVoteItem from "../models/IUserVoteItem";

async function getUserVote(userId: string): Promise<IUserVoteItem | null> {
  const res = await fetch(USER_API_ROUTE + "/" + userId, {
    method: "GET",
  });

  if (!res.ok) {
    return null;
  }

  const model: IUserVoteItem = await res.json();

  return model;
}

export default getUserVote;
