import React, { useEffect, useState } from "react";
// import "@fontsource-variable/fira-code";
import "./App.css";
import theme from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import HeaderBasic from "./HeaderBasic";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import CurrentLink from "./current-link/CurrentLink";

function App() {
  const [currentPage, setCurrentPage] = useState("home");

  useEffect(() => {
    if (window.location.href.includes("about")) {
      setCurrentPage("about");
    }
  }, []);

  useEffect(() => {
    if (
      !window.location.href.includes("voting") &&
      !window.location.href.includes("about")
    ) {
      setCurrentPage("current-link");
    }
  }, []);

  return (
    <div className="App" style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HeaderBasic onInfoOpen={() => {}} navigate={setCurrentPage} />
        {currentPage === "home" && <HomePage navigate={setCurrentPage} />}
        {currentPage === "about" && <AboutPage navigate={setCurrentPage} />}
        {currentPage === "current-link" && <CurrentLink />}
      </ThemeProvider>
    </div>
  );
}

export default App;
