import * as React from "react";
import colours from "../style/colours";

const styles = {
  link: {
    color: colours.primary,
    textDecoration: "underline",
    marginTop: 20,
    textTransform: "uppercase" as any,
    cursor: "pointer",
  },
};

function PageLinkBasic({
  text,
  onClick,
  backwards = false,
}: {
  text: string;
  onClick: () => void;
  backwards?: boolean;
}) {
  return (
    <div style={styles.link} onClick={onClick}>
      {text}
    </div>
  );
}

export default PageLinkBasic;
