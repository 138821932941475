import { VOTES_API_ROUTE } from "../apiRoutes";
import IVoteItem from "../models/IVoteItem";

async function addVote(url: string): Promise<IVoteItem | null> {
  const res = await fetch(VOTES_API_ROUTE, {
    method: "POST",
    body: JSON.stringify({ url }),
  });

  if (!res.ok) {
    console.log("vote failed");
    return null;
  }

  const model: IVoteItem = await res.json();

  return model;
}

export default addVote;
