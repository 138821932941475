import { VOTES_API_ROUTE } from "../apiRoutes";
import IVoteItem from "../models/IVoteItem";

async function voteForItem(url: string): Promise<IVoteItem | null> {
  const res = await fetch(VOTES_API_ROUTE, {
    method: "PUT",
    body: JSON.stringify({ url }),
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });

  if (!res.ok) {
    console.log("vote failed");
    return null;
  }

  const model: IVoteItem = await res.json();

  return model;
}

export default voteForItem;
