import * as React from "react";

function HeaderBasic({
  navigate,
  onInfoOpen,
}: {
  navigate: (x: string) => void;
  onInfoOpen: () => void;
}) {
  return (
    <div style={{ height: 30, marginTop: 15, fontSize: 22 }}>
      THE INKED LINK
    </div>
  );
}

export default HeaderBasic;
