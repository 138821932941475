import * as React from "react";
import VotingListItem from "./VotingListItem";
import LiveInListItem from "./LiveInListItem";
import { Box, Divider, List } from "@mui/material";
import IVoteItem from "../api/models/IVoteItem";

function VotingList({
  items,
  votedLink,
  setVotedLink,
}: {
  items: IVoteItem[];
  votedLink: string;
  setVotedLink: (x: string) => void;
}) {
  const sorted = items.sort((a, b) => b.votes - a.votes);

  if (!sorted || sorted.length === 0) {
    return <div style={{ marginTop: 30 }}>No votes yet</div>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <List
        sx={{
          width: "100%",
        }}
      >
        <Divider />
        <LiveInListItem />
        {sorted.map((item, i) => (
          <VotingListItem
            key={item.url}
            item={item}
            votedLink={votedLink}
            winning={i === 0}
            setVotedLink={setVotedLink}
          />
        ))}
      </List>
    </Box>
  );
}

export default VotingList;
