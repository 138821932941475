import { v4 as uuidv4 } from "uuid";

export function getUserId(): string {
  let userId = getCookie("userId");
  if (userId) {
    return userId;
  }

  userId = uuidv4();
  setCookie("userId", userId, 365);

  return userId;
}

function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname: string): string | null {
  let name = cname + "=";
  if (!document.cookie) {
    return null;
  }
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
