import React from "react";
import getCurrentWinner from "../api/winner/getCurrentWinner";

function CurrentLink() {
  getCurrentWinner().then((res) =>
    res ? window.location.replace(res.url) : {}
  );

  return <div></div>;
}

export default CurrentLink;
