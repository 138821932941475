import * as React from "react";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  Typography,
} from "@mui/material";
import { CheckCircle, ThumbUpSharp } from "@mui/icons-material";
import colours from "../style/colours";
import openInNewTab from "../common/helpers/openInNewTab";
import IVoteItem from "../api/models/IVoteItem";

const QR_CODE_BLACK =
  "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg";
const QR_CODE_PRIMARY =
  "https://png.pngtree.com/png-vector/20231220/ourmid/pngtree-blue-qr-code-png-image_11284921.png";

function VotingListItem({
  item,
  votedLink,
  winning,
  setVotedLink,
}: {
  item: IVoteItem;
  votedLink: string;
  winning: boolean;
  setVotedLink: (x: string) => void;
}) {
  const selected = item.url === votedLink;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          height: 80,
        }}
      >
        <ListItemButton
          sx={{
            bgcolor: "background.paper",
            "&:focus": {
              backgroundColor: "white",
            },
            padding: 0,
          }}
          selected={false}
          focusRipple={false}
          disableRipple={true}
          disableTouchRipple={true}
        >
          <Box
            component="img"
            sx={{
              width: 75,
              height: 75,
              marginLeft: -1,
            }}
            src={winning ? QR_CODE_PRIMARY : QR_CODE_BLACK}
            loading="lazy"
            onClick={() => openInNewTab(item.url)}
          />
          <Typography
            variant="body2"
            fontFamily={"Fira Code Variable"}
            style={{ textTransform: "uppercase" }}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              fontWeight: "500",
              maxHeight: "60px",
              paddingLeft: 1,
              paddingRight: 1,
              flexGrow: 100,
              color: winning ? colours.primary : null,
              textDecoration: "underline",
            }}
          >
            <div onClick={() => openInNewTab(item.url)}>{item.title}</div>
          </Typography>
          {!selected && (
            <Button
              variant="outlined"
              endIcon={<ThumbUpSharp />}
              onClick={() => setVotedLink(item.url)}
              sx={{
                marginRight: -0.5,
                color: colours.darkGray,
                borderRadius: 0,
                borderColor: "white",
              }}
              disabled={!!votedLink}
            >
              {item.votes}
            </Button>
          )}
          {selected && (
            <Button
              variant="outlined"
              endIcon={<CheckCircle />}
              sx={{ marginRight: -0.5, borderRadius: 0, borderColor: "white" }}
              disabled={true}
            >
              {item.votes}
            </Button>
          )}
        </ListItemButton>
      </Box>
      <Divider />
    </Box>
  );
}

export default VotingListItem;
