import { VOTES_API_ROUTE } from "../apiRoutes";
import IVoteItem from "../models/IVoteItem";

async function getTodaysVotes(): Promise<IVoteItem[]> {
  const res = await fetch(VOTES_API_ROUTE, {
    method: "GET",
  });

  if (!res.ok) {
    return [];
  }

  const model: IVoteItem[] = await res.json();

  return model;
}

export default getTodaysVotes;
