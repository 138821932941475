import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const styles = {
  paragraph: {
    marginBottom: 20,
  },
};

function InfoModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        width: "95%",
      }}
    >
      {/* <AppBar sx={{ position: "relative" }}> */}
      <AppBar position="static" sx={{ marginBottom: 5 }}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              How it works
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: 2 }}>
        <div style={styles.paragraph}>(to do explanatory text...)</div>
        <div style={styles.paragraph}>Submit or vote for a link every day!</div>
        <div style={{ height: 400 }}></div>
        <div style={{ textAlign: "center" }}>Briony / Tom / Andrew 2024</div>
      </Box>
    </Dialog>
  );
}

export default InfoModal;
