import { USER_API_ROUTE } from "../apiRoutes";
import IUserVoteItem from "../models/IUserVoteItem";

async function addUserVote(
  userId: string,
  url: string
): Promise<IUserVoteItem | null> {
  const res = await fetch(USER_API_ROUTE, {
    method: "POST",
    body: JSON.stringify({ userId, url }),
  });

  if (!res.ok) {
    return null;
  }

  const model: IUserVoteItem = await res.json();

  return model;
}

export default addUserVote;
