import React, { useEffect, useMemo, useState } from "react";
import "@fontsource-variable/fira-code";
import VotingList from "../voting-list/VotingList";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  TextField,
} from "@mui/material";
import InfoModal from "../info/InfoModal";
import colours from "../style/colours";
import PageLinkBasic from "../common/PageLinkBasic";
import openInNewTab from "../common/helpers/openInNewTab";
import getTodaysVotes from "../api/votes/getTodaysVotes";
import getCurrentWinner from "../api/winner/getCurrentWinner";
import addVote from "../api/votes/addVote";
import voteForItem from "../api/votes/voteForItem";
import IVoteItem from "../api/models/IVoteItem";
import { getUserId } from "../storage/userTracking";
import addUserVote from "../api/user/addUserVote";
import getUserVote from "../api/user/getUserVote";

function HomePage({ navigate }: { navigate: (x: string) => void }) {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [loadingVotes, setLoadingVotes] = useState(false);
  const [votingItems, setVotingItems] = useState<IVoteItem[]>([]);
  const [currentWinner, setCurrentWinner] = useState<IVoteItem | null>(null);
  const [votedLink, setVotedLink] = useState("");
  const [votedSnackOpen, setVotedSnackOpen] = useState(false);
  const [voteLoadingOpen, setVoteLoadingOpen] = useState(false);
  const [link, setLink] = useState("");
  const userId = useMemo(() => getUserId(), []);

  // load vote list
  useEffect(() => {
    setLoadingVotes(true);
    getTodaysVotes().then((res) => {
      setVotingItems(res);
      setLoadingVotes(false);
    });
  }, []);

  // load current winner
  useEffect(() => {
    getCurrentWinner().then((res) => setCurrentWinner(res));
  }, []);

  // load current vote
  useEffect(() => {
    getUserVote(userId).then((res) => setVotedLink(res?.url ?? ""));
  }, [userId]);

  async function onAddVote() {
    setVoteLoadingOpen(true);
    const vote = await addVote(link);
    setVoteLoadingOpen(false);
    if (vote) {
      setVotingItems([...votingItems, vote]);
      await addUserVote(userId, link);
      setVotedLink(link);
    }
  }

  async function onVote(url: string) {
    const item = await voteForItem(url);

    if (!item) {
      return;
    }

    const newItems = [...votingItems.filter((x) => x.url !== url), item];

    await addUserVote(userId, url);

    setVotingItems(newItems);
    setVotedLink(url);
    setVotedSnackOpen(true);
    setLink("");
  }

  return (
    <div>
      <PageLinkBasic text={"About Project"} onClick={() => navigate("about")} />
      <PageLinkBasic
        text={"View Today's Link"}
        onClick={() => (currentWinner ? openInNewTab(currentWinner.url) : {})}
      />
      <Container>
        <InfoModal
          open={infoModalOpen}
          onClose={() => {
            setInfoModalOpen(false);
          }}
        />
        <Box sx={{ marginTop: 4, marginBottom: 2, textAlign: "left" }}>
          Every 24 hours, the link with the most votes will become live on the
          QR code tattooed on the artist.
        </Box>
        <Box sx={{ marginBottom: 2, textAlign: "left" }}>
          Choose what link will be shown on their body tomorrow.
        </Box>
        <Box sx={{ marginBottom: 2, textAlign: "left" }}>
          Vote for a link below or add your own link.
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          width="100%"
          gap={2}
          marginBottom={2}
        >
          <TextField
            margin="dense"
            id="link"
            name="link"
            label="Link"
            type="url"
            fullWidth
            variant="standard"
            value={link}
            onChange={(x) => setLink(x.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: colours.darkGray,
              borderRadius: 0,
              whiteSpace: "nowrap",
            }}
            onClick={onAddVote}
            disabled={!link || !!votedLink}
          >
            Add Link
          </Button>
        </Box>

        {loadingVotes ? (
          <CircularProgress />
        ) : (
          <VotingList
            items={votingItems}
            votedLink={votedLink}
            setVotedLink={onVote}
          />
        )}
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={votedSnackOpen}
        onClose={() => setVotedSnackOpen(false)}
        message="Vote Accepted"
        autoHideDuration={3000}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={voteLoadingOpen}
        onClose={() => setVoteLoadingOpen(false)}
        message={<CircularProgress size={20} />}
        autoHideDuration={3000}
      />
    </div>
  );
}

export default HomePage;
